import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CloudApi } from '../helpers/cloud.api';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private isOnlineSubject = new BehaviorSubject<boolean>(true);
  public isOnline$ = this.isOnlineSubject.asObservable();

  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
  ) {
    interval(10000).subscribe(() => this.checkConnection());
  }

  private checkConnection() {
    this.http
      .get(this.cloudApi.ping)
      .pipe(catchError(() => of(null)))
      .subscribe((result) => {
        const newStatus = !!result;
        if (newStatus !== this.isOnlineSubject.value) {
          this.isOnlineSubject.next(newStatus);
        }
      });
  }
}
