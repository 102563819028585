import { Observable } from 'rxjs';

/**
 * Import and use this `BaseAuthService` whenever you need a component to use
 * either `AuthService` from the 'ikonix-front' app (website) or `OfflineAuthService`
 * from the 'offline-app' depending on which app is running.
 *
 * By using `provide` and `useClass` in the provides of `app.module.ts` and `offline.module.ts`
 * Angular's DI system will provide the indicated implementation.
 *
 * Both `AuthService` and `OfflineAuthService` extends from `BaseAuthService`.
 */
export abstract class BaseAuthService {
  abstract login(email: string, password: string): Observable<any>;
  abstract logout(): Observable<any>;
  abstract loadProfile(): Observable<any>;
  abstract updateProfile(data: any): Observable<any>;
}
